(function ($) {
  // Cache selectors
  const topHeight = 80;
  var lastId;
  var sideMenu = $(".sidebar-links");
      // All list items
  var menuItems = sideMenu.find("a");
      // Anchors corresponding to menu items
  var scrollItems = menuItems.map(function(){
        var href = $(this).attr("href");
        if (href === "#") {
          return;
        }
        var item = $(href);
        if (item.length) { return item; }
      });

  // Bind to scroll
  $("#content").scroll(function(){
    // Get id of current scroll item
    var cur = scrollItems.map(function(){
      if ($(this).offset().top < topHeight) {
        return this;
      }
    });
    // Get the id of the current element
    cur = cur[cur.length-1];
    var id = (cur && (
        cur.length ? cur[0].id : ""));

    if (lastId !== id) {
        lastId = id;
        // Set/remove active class
        menuItems.parent().removeClass("selected");
        var selectedItem = menuItems.filter("[href='#"+id+"']");
        selectedItem.parents(".link").addClass("selected").end()
                    .parent("li").addClass("selected");
    }
  });
}(jQuery));
